






























































import Vue from "vue";
import { Watch, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  components: {

  },
  computed: {
    ...mapState([
      'activeTab',
      'upcomingFestEvents'
    ])
  }
})
export default class Upcoming extends Vue {
  activeTab!: boolean
  loading = true
  upcomingFestEvents!: [any]

  private async created() {
    await this.$store.dispatch('loadUpcomingFests')
    this.loading = false
  }

}
